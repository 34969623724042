*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    font-family: 'Poppins';
    background-color: rgb(24,24,24);
    overflow: hidden;
}   

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 1px;
    border-radius: 1px;
    background: rgba(58, 57, 57, 0.8); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}


a { 
    color: #2796c9;
    text-decoration: none;
     }

a:hover { 
    color: #33b4ef;
     border-bottom: 1px solid #33b4ef;
     text-decoration: none; 
     }

iframe{
    height: 35vh;
    width: 30vw;
    margin-left: 2%;
    margin-right: 2%;
}

h1 { 
    color: #c9d0d4;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 46px; font-weight: 100;
    line-height: 50px;
    letter-spacing: 1px;
    padding: 20px 0 40px;
    margin-top: 20px;
    border-bottom: double #2796c9;
    text-align: center;
         }
h2 { 
    color: #c9d0d4;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 38px; font-weight: 100;
    line-height: 50px;
    letter-spacing: 1px;
    padding-top: 10px;
    text-align: center;
    border-bottom: double #2796c9;
         }
h2 a { 
            color:#ffffff;
            font-family: 'Helvetica Neue', sans-serif;
            font-size: 38px; font-weight: 100;
            line-height: 50px;
            letter-spacing: 1px;
            padding-top: 10px;
            text-align: center;
            text-decoration: none;
                 }
h2 a:hover{
        color: #33b4ef;
         text-decoration: none; 
}
h3 { 
    color: #c9d0d4;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 20px; font-weight: 700;
   
    line-height: 50px;
    letter-spacing: 1px;
    padding: 10 0 10px;
}
h4 { 
    color: #c9d0d4;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 20px; font-weight: 100;
    font-style: italic;
    line-height: 26px;
    letter-spacing: 1px;
    padding: 10 0 10px;
    align-items: center;
}
p { 
    color: #bbc3c8;
    font-family: 'Verdana', sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-indent: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-wrap: break-word;
    margin: 0;
 }


.date { 
    color: #bbc3c8;
    background: #292929;
    display: inline-block;
    font-family: 'Georgia', serif;
    font-style: italic;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 20px 18px;
    padding: 10px 12px 8px;
    position: absolute;
    bottom: -36px;
             }
#loading-screen {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 1;
 	transition: 1s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

  

  /* For Tablet-Desktop Size: */
@media only screen and (min-width: 768px) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Display 3 columns */
      column-gap: 10px; /* Add some gap between items */
    }
    .row {
        display: flex;
        
      }
    
    .grid-item {
      padding: 10px;
      font-size: 30px;
      text-align: center;
    }
    .column {
        flex: 48%;
        column-gap: 10px; /* Add some gap between items */

      }
      :root {
        --contentvw: 60%;
        --contentmargin: 10%;
      }
      
  }

  /* For Mobile Size: */
  @media only screen and (max-width: 767px) {
    .grid-container {
      display: block; /* Change to block display for mobile */
    }
  
    .grid-item {
      padding: 10px;
      font-size: 20px; /* Adjust font size for mobile */
      text-align: center;
    }
    .row {
        display: flex;
        flex-direction: column;
      }
    .column {
        flex: 100%;
      }
    iframe{
        height: 35vh;
        width: 90%;
         margin-left: 2%;
        margin-right: 2%;
    }
    :root {
        --contentvw: 100%;
        --contentmargin: 30%;
      }
      
  }
#projects-area {
    visibility: hidden;
	position: absolute;
    align-items: center;
    background-color: #292929;
	width: var(--contentvw);
	height: 70%;
    margin: auto;
    padding: 4%;
    margin-top: var(--contentmargin);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	opacity: 0;
    overflow-y: visible;
    overflow-x: hidden;
 	transition: 0.5s opacity;
    
}
#projects-area.fade-in {
    opacity: 0.95;
}
#skills-area {
    visibility: hidden;
	position: absolute;
    align-items: center;
    background-color: #292929;
	width: var(--contentvw);
	height: 70%;
    margin: auto;
    padding: 4%;
    margin-top: var(--contentmargin);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	opacity: 0;
    overflow-y: visible;
    overflow-x: hidden;
 	transition: 0.5s opacity;
    
}
#skills-area.fade-in {
    opacity: 0.95;
}
#contact-area {
    visibility: hidden;
	position: absolute;
    align-items: center;
    background-color: #292929;
	width: var(--contentvw);
	height: 70%;
    margin: auto;
    padding: 4%;
    margin-top: var(--contentmargin);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	opacity: 0;
    overflow-y: visible;
    overflow-x: hidden;
 	transition: 0.5s opacity;
    
}
#contact-area.fade-in {
    opacity: 0.95;
}
/*# sourceMappingURL=main.css.map*/